import React, { useEffect, useState } from "react";
import styles from "./Testimonials.module.css";
import title from "../../assets/images/testimonialTitle.png";
import card1 from "../../assets/images/testimonialCard1.png";
import card2 from "../../assets/images/testimonialCard2.png";
import Slider from "../../UI/Slider/Slider";

interface ITestimonials {
    children?: React.ReactElement;
}

const Testimonals: React.FC<ITestimonials> = ({ children }) => {

    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const intro_s = document.getElementById("testimonials") as HTMLElement;
        const h = intro_s?.offsetTop as number;

        document.addEventListener('scroll', () => {
            if (!(window.scrollY < (h+1500))) {
                setVisible(true);
            }
        })
    })
    const [isDesktop] = useState<boolean>(window.innerWidth >= 600);

    return (
        <div className={styles.testimonials} id="testimonials">
            <div className={styles.title}>
                <img src={title} className={isVisible ? styles.bottomtopup : styles.none} />
            </div>

            <div className={isVisible ? styles.bottomtopup : styles.none}>
                {
                    isDesktop ? <div className={styles.section}>

                        <div className={styles.card} >
                            <img src={card1} />
                        </div>
                        <div className={styles.card}>
                            <img src={card2} />
                        </div>

                    </div> :
                        <div>
                            <Slider elementsPerSlide={1} elementsToMove={1}>
                                <div className={styles.card} >
                                    <img src={card1} />
                                </div>
                                <div className={styles.card}>
                                    <img src={card2} />
                                </div>
                            </Slider>
                        </div>
                }
            </div>




        </div>
    )
}

export default Testimonals;