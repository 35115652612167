import React, { useEffect, useState } from "react";
import styles from "./Onu.module.css";
import onu from "../../assets/images/onu.png";

import onu1 from "../../assets/images/mobile/onu1.png";
import onu2 from "../../assets/images/mobile/onu2.png";
import onu3 from "../../assets/images/mobile/onu3.png";
import onu4 from "../../assets/images/mobile/onu4.png";

interface OnuProps {
    children?: React.ReactElement;
}

const Onu: React.FC<OnuProps> = () => {

    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const intro_s = document.getElementById("projeto_pedagogico") as HTMLElement;

        const h = intro_s?.offsetTop as number;

        document.addEventListener('scroll', () => {
            if (!(window.scrollY < h )) {
                setVisible(true);
            }
        })
    })


    const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth >= 600);
    return (
        <div className={styles.onu} id="projeto_pedagogico">
            <div className={isVisible ? styles.end : styles.none}><span className={styles.h1}><b>ALINHADOS COM O FUTURO:</b></span></div>

            <span className={styles.subtitle}>
                <div className={isVisible ? styles.fade : styles.none}>
                    Nossa empresa é comprometida com quatro dos Objetivos
                    de <b>Desenvolvimento Sustentável da ONU.</b>
                </div>
            </span>
            <div className={styles.values}>
                <div className={isVisible ? styles.uptodown : styles.none}>
                    {isDesktop ? <img src={onu} draggable={false} /> : <div className={styles.gridContainer}>

                        <div className={styles.gridItem}>
                            <img className={styles.gridItemImage} src={onu1} alt="Educação de qualidade" />
                        </div>
                        <div className={styles.gridItem}>
                            <img className={styles.gridItemImage} src={onu2} alt="Trabalho decente e crescimento econômico" />
                        </div>
                        <div className={styles.gridItem}>
                            <img className={styles.gridItemImage} src={onu3} alt="Indústria, inovação e infraestrutura" />
                        </div>
                        <div className={styles.gridItem}>
                            <img className={styles.gridItemImage} src={onu4} alt="Redução das desigualdades" />
                        </div>
                    </div>}

                </div>

            </div>
        </div>
    )
}

export default Onu;