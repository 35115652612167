import React from "react";
import styles from "./Banner.module.css";
import layer from "../../assets/images/bannerLayer.png";


interface BannerProps {
    children?: React.ReactElement;
}

const Banner: React.FC<BannerProps> = () => {

    return (
        <div className={styles.banner}>
            <img src={layer} className={styles.bannerLayer} />
        </div>
    )
}

export default Banner;