import React, { useState } from "react";
import styles from "./Footer.module.css";
import logoIcon from "../../assets/images/logo.png";

import footerSocial1 from "../../assets/images/footerSocial1.png";
import footerSocial2 from "../../assets/images/footerSocial2.png";
import footerSocial3 from "../../assets/images/footerSocial3.png";

import footerContact from "../../assets/images/footerContact.png";

import { FiPhone } from "react-icons/fi"
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";

interface IFooter {
    children?: React.ReactElement;
}

const Footer: React.FC<IFooter> = () => {

    const [isDesktop] = useState<boolean>(window.innerWidth >= 600);

    return (
        <div>
            {
                isDesktop ? <div className={styles.footer}>
                    <div className={styles.footerLogoIcon}>

                        <img src={logoIcon} className={styles.logoIcon} />

                    </div>
                    <div className={styles.info}>

                        <div className={styles.columnContact}>
                            <div className={styles.centerElements}><FiPhone color="white" /> (61) 99431-5842</div>
                            <div className={styles.centerElements}><IoIosMail color="white" size={21} /> contato@editoradogomaker.com</div>
                        </div>

                        <div>
                            <div className={styles.centerElements}><FaMapMarkerAlt color="white" /> SCN Quadra 4 Bl. B Sala 702 Parte 1953<br /> Asa Norte - Brasília-DF</div>
                        </div>



                    </div>
                    <div className={styles.social}>
                        <div className={styles.socialMedia}>
                            <a href="https://www.tiktok.com/@editoradogomaker" target="_blank"><img src={footerSocial3} /></a>
                            <a href="https://www.instagram.com/dogomaker" target="_blank"><img src={footerSocial2} /></a>
                            <a href="https://www.facebook.com/dogomaker" target="_blank"><img src={footerSocial1} /></a>
                        </div>
                    </div>
                    <div className={styles.contact}>
                        <a href="mailto:contato@editoradogomaker.com">
                            <img src={footerContact} />
                        </a>

                    </div>
                </div> :

                    <div className={styles.footer}>


                        <div className={styles.footerLogoIcon}>
                            <img src={logoIcon} className={styles.logoIcon} />
                        </div>

                        <div className={styles.info}>

                            <div className={styles.columnContact}>
                                <div className={styles.centerElements}><FiPhone color="white" /> (61) 99431-5842</div>
                                <div className={styles.centerElements}><IoIosMail color="white" size={12} /> contato@editoradogomaker.com</div>
                                <div className={styles.centerElements}><FaMapMarkerAlt color="white" /> SCN Quadra 4 Bl. B Sala 702 Parte 1953<br /> Asa Norte - Brasília-DF</div>

                            </div>
                        </div>

                        <div className={styles.social}>
                            <div className={styles.socialMedia}>
                                <a href="https://www.tiktok.com/@editoradogomaker" target="_blank"><img src={footerSocial3} /></a>
                                <a href="https://www.instagram.com/dogomaker" target="_blank"></a><img src={footerSocial2} />
                                <a href="https://www.facebook.com/dogomaker" target="_blank"><img src={footerSocial1} /></a>
                            </div>

                        </div>

                    </div>


            }


        </div>

    )
}

export default Footer;