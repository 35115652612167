import React, { useEffect, useState } from "react";
import styles from "./Costumers.module.css";
import Slider from "../../UI/Slider/Slider";

import city1 from "../../assets/images/cities/city1.png";
import city2 from "../../assets/images/cities/city2.png";
import city3 from "../../assets/images/cities/city3.png";
import city4 from "../../assets/images/cities/city4.png";
import city5 from "../../assets/images/cities/city5.png";
import city6 from "../../assets/images/cities/city6.png";

import RoundedButtonExpanded from "../../UI/RoundedButtonExpanded/RoundedButtonExpanded";
import RoundedButtonExpandedMobile from "../../UI/RoundedButtonExpandedMobile/RoundedButtonExpandedMobile";


interface ICostumers {
    children?: React.ReactElement;
}

const Costumers: React.FC<ICostumers> = () => {

    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const intro_s = document.getElementById("clientes") as HTMLElement;
        const h = intro_s?.offsetTop as number;

        document.addEventListener('scroll', () => {
            if (!(window.scrollY < (h+1600))) {
                setVisible(true);
            }
        })
    })


    const [isDesktop] = useState<boolean>(window.innerWidth >= 600);

    return (
        <div className={styles.costumers} id="clientes">
            <div className={isVisible ? styles.uptodown : styles.none}>
                <div className={styles.title}>
                    CLIENTES
                </div>
            </div>

            <div className={isVisible ? styles.bottomtopup : styles.none}>
                <div className={styles.roundedExpandedCard}>

                    {
                        isDesktop ? <RoundedButtonExpanded>
                            + DE 20 MUNICÍPIOS <span className={styles.whiteColor}>ADERIRAM À</span> EDUCAÇÃO 4.0
                        </RoundedButtonExpanded> : <RoundedButtonExpandedMobile>
                            + DE 20 MUNICÍPIOS <span className={styles.whiteColor}>ADERIRAM À</span> EDUCAÇÃO 4.0
                        </RoundedButtonExpandedMobile>
                    }

                </div>
            </div>
            <div className={isVisible ? styles.uptodown : styles.none}>
                <div className={styles.roundedExpandedCard}>
                    {
                        isDesktop ? <RoundedButtonExpanded>
                            + DE 10 MIL ALUNOS <span className={styles.whiteColor}>IMPACTADOS</span>
                        </RoundedButtonExpanded> : <RoundedButtonExpandedMobile>
                            + DE 10 MIL ALUNOS <span className={styles.whiteColor}>IMPACTADOS</span>
                        </RoundedButtonExpandedMobile>
                    }
                </div></div>
            {/* Slides */}
            <div className={isVisible ? styles.start : styles.none}>
            <div className={styles.cities}>
                <Slider elementsPerSlide={3} elementsToMove={1}>
                    <img src={city1} className={city1} />
                    <img src={city2} className={city1} />
                    <img src={city3} className={city1} />
                    <img src={city4} className={city1} />
                    <img src={city5} className={city1} />
                    <img src={city6} className={city1} />
                </Slider>
            </div>
            </div>
        </div>
    )
}

export default Costumers;