import React, { useState } from "react";
import styles from "./Slider.module.css";
import leftArrow from "../../assets/images/sliderLeftArrow.png";
import rightArrow from "../../assets/images/sliderRightArrow.png";

interface SliderProps {
  children: React.ReactNode[];
  elementsPerSlide: number;
  elementsToMove: number;
}

const Slider: React.FC<SliderProps> = ({
  children,
  elementsPerSlide,
  elementsToMove,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => {
      const maxIndex = children.length - elementsPerSlide;
      let nextIndex = prevIndex + elementsToMove;
      if (nextIndex > maxIndex) nextIndex = 0; // Wrap to start if exceeding
      return nextIndex;
    });
  };

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) => {
      let nextIndex = prevIndex - elementsToMove;
      if (nextIndex < 0) {
        // Calculate start index of the last full slide
        const maxIndex = children.length - elementsPerSlide;
        nextIndex = maxIndex - (maxIndex % elementsToMove);
        if (nextIndex < 0 || elementsPerSlide >= children.length) nextIndex = 0; // Adjust for small sets
      }
      return nextIndex;
    });
  };

  const visibleChildren = children.slice(
    currentIndex,
    currentIndex + elementsPerSlide
  );

  return (
    <div className={styles.slider}>
      <div className={styles.leftArrow} onClick={handlePrevSlide}>
        <img src={leftArrow} alt="Left Arrow" />
      </div>
      <div className={styles.sliderContent}>
        {visibleChildren.map((child, index) => (
          <div key={index} className={styles.sliderItem}>
            {child}
          </div>
        ))}
      </div>
      <div className={styles.rightArrow} onClick={handleNextSlide}>
        <img src={rightArrow} alt="Right Arrow" />
      </div>
    </div>
  );
};

export default Slider;
