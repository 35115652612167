import React, { useEffect, useState } from "react";
import styles from "./Press.module.css";
import press from "../../assets/images/pressTitle.png";
import roundedExpandedCard from "../../assets/images/pressRoundedExpandedCard.png";
import Slider from "../../UI/Slider/Slider";

interface IPress {
    children?: React.ReactElement;
}

const Press: React.FC<IPress> = ({ children }) => {

    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const intro_s = document.getElementById("imprensa") as HTMLElement;
        const h = intro_s?.offsetTop as number;

        document.addEventListener('scroll', () => {
            if (!(window.scrollY < (h+1300))) {
                setVisible(true);
            }
        })
    })


    const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth >= 600);

    return (
        <div className={styles.press} id="imprensa">
            <div className={styles.title}>
                <img src={press} className={isVisible ? styles.uptodown : styles.none} />
            </div>
            <div className={isVisible ? styles.end : styles.none} >
                {
                    isDesktop ? <div className={styles.videos}>
                        <div className={styles.video1}>
                            <iframe width="560" height="400" src="https://www.youtube.com/embed/6S5FReCwQRs?si=avQ_fUANOrf9-3XG" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                        </div>
                        <div className={styles.video2}>
                            <iframe width="560" height="400" src="https://www.youtube.com/embed/-5s8ubMsD-I?si=V7p99lE_UcgyYVBh" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                        </div>
                    </div> : <div className={styles.mobile_videos_slider}>
                        <Slider elementsPerSlide={1} elementsToMove={1} >

                            <div className={styles.video1}>
                                <iframe width="220" height="150" src="https://www.youtube.com/embed/6S5FReCwQRs?si=avQ_fUANOrf9-3XG" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
                            </div>
                            <div className={styles.video2}>
                                <iframe width="220" height="150" src="https://www.youtube.com/embed/-5s8ubMsD-I?si=V7p99lE_UcgyYVBh" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
                            </div>
                        </Slider>
                    </div>
                }

            </div>
            <div className={styles.start}>
                <div className={styles.roundedExpandedCard}>
                    <a href="https://www.youtube.com/channel/UCyIfuHLDud18jEbbiSlIiHQ" target="_blank"><img src={roundedExpandedCard} /></a>
                </div>
            </div>

        </div>
    )
}

export default Press;