import React, { useEffect, useState } from "react";
import styles from "./Earth.module.css";

import earthTitle from "../../assets/images/earthTitle.png"
import firstColumnImage from "../../assets/images/earthPeople.png";
import secondColumnImage from "../../assets/images/recycle.png";

import margareth1 from "../../assets/images/mobile/margareth1.png";
import margareth2 from "../../assets/images/mobile/margareth2.png";

interface IEarth {
    children?: React.ReactElement;
}

const Earth: React.FC<IEarth> = () => {

    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const intro_s = document.getElementById("earth") as HTMLElement;

        const h = intro_s?.offsetTop as number;

        document.addEventListener('scroll', () => {
            if (!(window.scrollY < (h+800))) {
                setVisible(true);
             
            }
        })
    })

    const [isDesktop, setDesktop] = useState(window.innerWidth >= 600);

    return (
        <div className={styles.earth} id="earth">
            <div className={styles.title}>
                <img src={earthTitle} className={isVisible ? styles.start : styles.none} />
            </div>
            <div className={styles.customSection}>
                <div className={styles.firstColumn}>
                    <div className={isVisible ? styles.end : styles.none} >
                    <div className={styles.text}>
                        Nossa abordagem incorpora métodos inovadores para<br />
                        conscientizar os alunos sobre a importância da<br />
                        sustentabilidade e como a tecnologia pode ser usada<br />
                        para criar um futuro mais verde, promovendo o uso e<br />
                        reaproveitamento responsável de materiais. Ao usar<br />
                        nossos kits de robótica, os alunos aprendem a projetar e<br />
                        construir soluções ecológicas, capacitando-os a fazer a<br />
                        diferença e se tornar agentes de mudança em nosso<br />
                        mundo em constante evolução. Juntos, estamos<br />
                        construindo um futuro mais sustentável e promissor.
                    </div></div>

                <div className={styles.firstColumnImage}>
                    <img src={firstColumnImage} className={isVisible ? styles.bottomtopup : styles.none}/>
                </div>
            </div>
            <div className={isVisible ? styles.uptodown : styles.none} >
            <div className={styles.secondColumn}>
                {
                    isDesktop ? <img src={secondColumnImage} /> : <div className={styles.imageRow}>
                        <div className={styles.imageWrapper}>
                            <img className={styles.responsiveImage} src={margareth1} alt="Imagem Margareth 1" />
                        </div>
                        <div className={styles.imageWrapper}>
                            <img className={styles.responsiveImage} src={margareth2} alt="Imagem Margareth 2" />
                        </div>
                    </div>
                }


            </div>
            </div>
        </div>

        </div >
    )
}

export default Earth;