import React, { useState } from "react";
import styles from "./Card.module.css";
interface CardProps {
    title: string;
    text: string;
    secondary?: boolean;
    side?: 'left' | 'right';
}

const Card: React.FC<CardProps> = ({ title, text, secondary, side }) => {

    const [cardColor] = useState(secondary ? 'rgba(255, 0, 0, 0.8)' : '#012a54d0');
    const [isDesktop] = useState<boolean>(!(window.innerWidth < 600))

    //  border-bottom-left-radius
    //  border-bottom-left-radius


    return (
        <div className={styles.card} style={{
            backgroundColor: cardColor,
            borderBottomLeftRadius: side === 'left' && isDesktop ? '150px' : 'none',
            borderBottomRightRadius: side === 'right' && isDesktop ? '150px' : 'none'
        }}>
            <div className={styles.title}>
                {title}
            </div>
            <div className={styles.text}>
                {text}
            </div>
        </div>
    )
}

export default Card;