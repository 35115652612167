import Navbar from "./Components/Navbar/Navbar";
import Banner from "./Components/Banner/Banner";
import IntroSection from "./Components/IntroSection/IntroSection";
import Onu from "./Components/Onu/Onu";
import Values from "./Components/Values/Values";
import Materials from "./Components/Materials/Materials";
import Earth from "./Components/Earth/Earth";
import Costumers from "./Components/Costumers/Costumers";
import Press from "./Components/Press/Press";
import Testimonals from "./Components/Testimonials/Testimonials";
import Cases from "./Components/Cases/Cases";
import Footer from "./Components/Footer/Footer";


function App() {
  return (
    <div className="App">

      <Navbar />
      <Banner />
      <IntroSection />
      <Onu />
  <Values />

      <Materials />
      <Earth />
      <Costumers />
      
      <Press />
      <Testimonals />
      <Cases />
      <Footer />
    </div>
  );
}

export default App;
