import React, { useEffect, useState } from "react";
import styles from "./Cases.module.css";
import roundedExpandedCard from "../../assets/images/casesRoundedExpandedButton.png"
import Slider from "../../UI/Slider/Slider";
interface ICases {
    children?: React.ReactElement;
}

const Cases: React.FC<ICases> = () => {

    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const intro_s = document.getElementById("cases") as HTMLElement;
        const h = intro_s?.offsetTop as number;

        document.addEventListener('scroll', () => {
            if (!(window.scrollY < (h+1200))) {
                setVisible(true);
            }
        })
    })

    const [isDesktop] = useState<boolean>(window.innerWidth >= 600);

    return (
        <div className={styles.cases} id="cases">
            <div className={isVisible ? styles.bottomtopup : styles.none}>
            <div className={styles.title}>
                CASES DE SUCESSO
            </div>

            </div>
      
            <div className={isVisible ? styles.uptodown: styles.none}>
            {
                isDesktop ? <div className={styles.videos}>
                    <div className={styles.video}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/orVISAh9c-A?si=cMdrgwUe-vVLp6-t" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" frameBorder={0}></iframe>
                    </div>
                    <div className={styles.video}>
                        <iframe width="660" height="415" src="https://www.youtube.com/embed/bDBw-fL_nbE?si=9t4C4m6joDeFc-kf" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                    </div>

                    <div className={styles.video}>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/jzX6fI3O1dA?si=JUfeHbmlL98AxYhT" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    </div>
                </div> : <div className={styles.videos}>
                    <Slider elementsPerSlide={1} elementsToMove={1}>
                        <div className={styles.video}>
                            <iframe width="220" height="150" src="https://www.youtube.com/embed/orVISAh9c-A?si=cMdrgwUe-vVLp6-t" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" frameBorder={0}></iframe>
                        </div>
                        <div className={styles.video}>
                            <iframe width="220" height="150" src="https://www.youtube.com/embed/bDBw-fL_nbE?si=9t4C4m6joDeFc-kf" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                        </div>

                        <div className={styles.video}>
                            <iframe width="220" height="150" src="https://www.youtube.com/embed/jzX6fI3O1dA?si=JUfeHbmlL98AxYhT" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                        </div>
                    </Slider>
                </div>
            }
            </div>

            <div className={isVisible ? styles.start: styles.none}>
            <div className={styles.roundedExpandedCard}>
                <a href="https://www.youtube.com/channel/UCyIfuHLDud18jEbbiSlIiHQ" target="_blank">
                    <img src={roundedExpandedCard} />
                </a>
            </div>
            </div>
        </div>
    )
}

export default Cases;