import React, { PropsWithChildren, ButtonHTMLAttributes } from "react";
import styles from "./RoundedButton.module.css";


interface RoundedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{

}

const RoundedButton: React.FC<PropsWithChildren<RoundedButtonProps>> = ({ children, ...props }) => {
    return (
        <button className={styles.button} {...props}>
            {children}
        </button>
    )
}

export default RoundedButton;