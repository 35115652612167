import React, { SyntheticEvent } from "react";
import styles from "../Materials.module.css";


import materialsRobotica from "../../../assets/images/materialsRobotica.png";
import materialsPensamento from "../../../assets/images/materialsPensamento.png";
import RoundedButton from "../../../UI/RoundedButton/RoundedButton";
import Slider from "../../../UI/Slider/Slider";

interface IBooksComponentMobile {
    setPensamento: (id: string) => void;
    setBookWindowStyle: (style: string) => void; // Substitua `any` pelo tipo de estilo apropriado
}

const BooksComponentMobile: React.FC<IBooksComponentMobile> = ({setPensamento, setBookWindowStyle}) => {

    const getWidth = (e: SyntheticEvent) => {
        const el = e.target as HTMLElement;
        setPensamento(el.id as string);
        setBookWindowStyle(styles.bookWindow);
    }


    return (
        <>

            <Slider elementsPerSlide={1} elementsToMove={1}>
                <div className={styles.icon}>

                    <div className={styles.textIcon}>
                        <div className={styles.image_and_title}>
                            <img src={materialsPensamento} />
                            <div className={styles.titleBookDescription}>
                                INICIAÇÃO AO DESENVOLVIMENTO
                                DO PENSAMENTO COMPUTACIONAL
                                COM PROGRAMAÇÃO VISUAL
                            </div>
                        </div>

                        <div className={styles.bookDescription}>
                            Compreender o cenário atual da educação e sua relação com a
                            tecnologia é fundamental para preparar os estudantes para os
                            desafios do século 21. O livro surge como parte integrante desse
                            movimento, oferecendo uma abordagem abrangente e prática para
                            desenvolver o Pensamento Computacional.
                        </div>
                        <RoundedButton onClick={getWidth} id="saiba1" >
                            SAIBA MAIS +
                        </RoundedButton>
                    </div>

                </div>

                <div className={styles.icon}>

                    <div className={styles.textIcon}>

                        <div className={styles.image_and_title}>
                            <img src={materialsRobotica} />
                            <div className={styles.titleBookDescription}>
                                ROBÓTICA EDUCACIONAL SUSTENTÁVEL COM PROGRAMAÇÃO VISUAL
                            </div>
                        </div>

                        <div className={styles.bookDescription}>
                            O livro tem uma abordagem inovadora e sustentável para a introdução
                            da robótica educacional no contexto escolar. Ele se concentra em
                            fornecer aos educadores e alunos uma compreensão sólida dos conceitos
                            fundamentais da robótica, enquanto promove a sustentabilidade
                            e o uso responsável dos recursos.
                        </div>

                        <RoundedButton onClick={getWidth} id="saiba2">
                            SAIBA MAIS +
                        </RoundedButton>


                    </div>
                </div>
            </Slider>

        </>
    )
}

export default BooksComponentMobile;