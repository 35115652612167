import React, { ButtonHTMLAttributes } from "react";
import styles from "./RoundedButtonExpandedMobile.module.css";

interface RoundedButtonExpandedProps extends ButtonHTMLAttributes<HTMLButtonElement>{
    children?: React.ReactNode;
    color?: 'primary' | 'secondary' | 'tertiary';
}

const RoundedButtonExpandedMobile: React.FC<RoundedButtonExpandedProps> = ({ children, ...props }) => {
    return (
        <button className={styles.button} {...props}>
            <div>
                {children}
            </div>
        </button>
    )
}

export default RoundedButtonExpandedMobile;