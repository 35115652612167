import React, { RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import styles from "./Materials.module.css";
import banner from "../../assets/images/materialsBanner.png";

import materials1 from "../../assets/images/materials1.png";
import materials2 from "../../assets/images/materials2.png";
import materials3 from "../../assets/images/materials3.png";
import materials4 from "../../assets/images/materials4.png";

import materialsRobotica from "../../assets/images/materialsRobotica.png";
import materialsPensamento from "../../assets/images/materialsPensamento.png";
import RoundedButton from "../../UI/RoundedButton/RoundedButton";
import BooksComponentMobile from "./Components/BooksComponentMobile";
import { IoMdClose } from "react-icons/io";

interface IMaterials {
    children?: React.ReactElement;
}



function useOutsideClick<T extends HTMLElement>(
    ref: RefObject<T>,
    callback: () => void
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {

        };
    }, [ref, callback]);
}

const Materials: React.FC<IMaterials> = () => {



    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const intro_s = document.getElementById("materials") as HTMLElement;
        const h = intro_s?.offsetTop as number;

        document.addEventListener('scroll', () => {
            if (!(window.scrollY < (h))) {
                setVisible(true);
            }
        })
    })



    const [bookWindowStyle, setBookWindowStyle] = useState<string>(styles.bookWindowNone);
    const [isPensamento, setPensamento] = useState<string>("saiba1");
    const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth >= 600);
    const [active, setActive] = useState<boolean>(false);

    const getWidth = (e: SyntheticEvent) => {
        const el = e.target as HTMLElement;
        setPensamento(el.id);
        setBookWindowStyle(styles.bookWindow);
        setActive(state => !state);
    }



    const wrapperRef = useRef(null);

    const wrapperRef2 = useRef(null);

    useOutsideClick(wrapperRef, () => {
        active && setBookWindowStyle(styles.bookWindowNone)
    })


    return (
        <>
            <div className={styles.materials} id="materials">

                <div className={styles.customSection}>

                    <div className={styles.banner}>
                        <img src={banner} className={isVisible ? styles.bottomtopup : styles.none} />
                    </div>


                    <div className={isVisible ? styles.uptodown : styles.none}>
                    <div className={styles.list}>
                        <div>
                            <div className={styles.el_list}>
                                <img src={materials4} />

                            </div>

                            <div className={styles.el_list}>
                                <img src={materials2} />
                            </div>
                        </div>

                        <div>

                            <div className={styles.el_list}>
                                <img src={materials3} />
                            </div>

                            <div className={styles.el_list}>
                                <img src={materials1} />
                            </div>
                        </div>


                    </div>
                    </div>

                </div>
                <div className={styles.footer}>

                    {
                        isDesktop ? <div className={isVisible ? styles.start: styles.none}>
                            <div className={styles.icon}>
                                <img src={materialsPensamento} />
                                <div className={styles.textIcon}>
                                    <div className={styles.titleBookDescription}>
                                        INICIAÇÃO AO DESENVOLVIMENTO
                                        DO PENSAMENTO COMPUTACIONAL
                                        COM PROGRAMAÇÃO VISUAL
                                    </div>
                                    <br />
                                    <div className={styles.bookDescription}>
                                        Compreender o cenário atual da educação e sua relação com a
                                        tecnologia é fundamental para preparar os estudantes para os
                                        desafios do século 21. O livro surge como parte integrante desse
                                        movimento, oferecendo uma abordagem abrangente e prática para
                                        desenvolver o Pensamento Computacional.
                                    </div>
                                    <br />
                                    <RoundedButton onClick={getWidth} id="saiba1">
                                        SAIBA MAIS +
                                    </RoundedButton>
                                </div>

                            </div>

                            <div className={styles.icon}>
                                {<img src={materialsRobotica} />}
                                <div className={styles.textIcon}>
                                    <div className={styles.titleBookDescription}>
                                        ROBÓTICA EDUCACIONAL SUSTENTÁVEL COM PROGRAMAÇÃO VISUAL
                                    </div>
                                    <br />
                                    <div className={styles.bookDescription}>
                                        O livro tem uma abordagem inovadora e sustentável para a introdução
                                        da robótica educacional no contexto escolar. Ele se concentra em
                                        fornecer aos educadores e alunos uma compreensão sólida dos conceitos
                                        fundamentais da robótica, enquanto promove a sustentabilidade
                                        e o uso responsável dos recursos.
                                    </div>
                                    <br />
                                    <RoundedButton onClick={getWidth} id="saiba2">
                                        SAIBA MAIS +
                                    </RoundedButton>
                                </div>
                            </div>
                            </div> : <div className={isVisible ? styles.start1: styles.none}><BooksComponentMobile setPensamento={setPensamento} setBookWindowStyle={setBookWindowStyle} /></div>
                    }



                </div>



            </div>
            <div id="bookwindow" className={bookWindowStyle} ref={wrapperRef}>
                <div className={styles.close}>
                    <IoMdClose size={32} onClick={() => {
                        setBookWindowStyle(styles.bookWindowNone)
                    }} />
                </div>
                {/*   <div>
                    <img src={isPensamento === "saiba1" ? materialsSaiba1 : materialsSaiba2} ref={wrapperRef} />
                </div>
 */
                }
                <div className={styles.bannerContainer}>
                    { /* PARTE DA IMAGEM
                        <div className={styles.imageContainer}>
                        <img src={isPensamento === "saiba1" ? materialsPensamento : materialsRobotica} width={150} ref={wrapperRef} />
            </div> */}
                    <div className={styles.header}>
                        {isPensamento === "saiba1" ? 'INICIAÇÃO AO DESENVOLVIMENTO DO PENSAMENTO COMPUTACIONAL COM PROGRAMAÇÃO VISUAL' : 'ROBÓTICA EDUCACIONAL SUSTENTÁVEL COM PROGRAMAÇÃO VISUAL'}

                    </div>

                    {
                        isPensamento === "saiba1" ? <div className={styles.body}>
                            <div className={styles.leftSection}>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Educação 4.0:</div>
                                    <ul className={styles.list1}>
                                        <li>Reconhecimento da revolução tecnológica.</li>
                                        <li>Necessidade de uma educação que acompanhe o ritmo atual.</li>
                                        <li>Inclusão de crianças e jovens no mundo tecnológico.</li>
                                        <li>Promoção de habilidades como pensamento computacional, cálculo mental e raciocínio lógico.</li>
                                    </ul>
                                </div>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Metodologias Ativas da Educação:</div>
                                    <ul className={styles.list1}>
                                        <li>Educação Maker e Aprendizado STEAM.</li>
                                        <li>Protagonismo do estudante e do professor.</li>
                                        <li>Aprendizagem prática e interativa.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={styles.rightSection}>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Alinhamento com a BNCC:</div>
                                    <ul className={styles.list1}>
                                        <li>Conteúdos transversais e multidisciplinares.</li>
                                        <li>Adequação ao currículo diversificado da Educação Básica.</li>
                                    </ul>
                                </div>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Estrutura do Livro:</div>
                                    <ul className={styles.list1}>
                                        <li>Reflexões sobre metodologias de ensino.</li>
                                        <li>Instruções para instalação de software.</li>
                                        <li>Atividades práticas ("Mão na Massa").</li>
                                        <li>Dicas dos mestres. Exploração transdisciplinar.</li>
                                        <li>Introdução aos Apps & Games.</li>
                                    </ul>
                                </div>
                            </div>
                        </div> : <div className={styles.body}>
                            <div className={styles.leftSection}>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Fundamentos da Robótica Educacional:</div>
                                    <ul className={styles.list1}>
                                        <li>Explora os princípios básicos da robótica educacional, incluindo mecânica, eletrônica e ciência da computação.</li>
                                    </ul>
                                </div>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Sustentabilidade na Robótica Educacional:</div>
                                    <ul className={styles.list1}>
                                        <li>Destaca a importância da sustentabilidade, apresentando métodos de construção e programação de robôs com materiais de baixo custo e alternativos.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className={styles.rightSection}>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Programação Visual com Arduino:</div>
                                    <ul className={styles.list1}>
                                        <li>Dedica-se ao uso do Arduino, uma plataforma de hardware de código aberto, ensinando programação de forma visual.</li>
                                    </ul>
                                </div>
                                <div className={styles.box}>
                                    <div className={styles.title1}>Integração Curricular e STEAM:</div>
                                    <ul className={styles.list1}>
                                        <li>Enfatiza a integração da robótica educacional com disciplinas como ciências, matemática, artes e tecnologia, oferecendo exemplos práticos de aplicação interdisciplinar.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                </div>

            </div>
        </>

    )
}

export default Materials;