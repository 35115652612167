import React, { useEffect, useState } from "react";
import Card from "../../UI/Card/Card";
import styles from "./Values.module.css";

import { visionText, missionText, valuesText } from "./texts";
import Slider from "../../UI/Slider/Slider";

interface ValuesProps {

}

const Values: React.FC<ValuesProps> = ({ }) => {

    const [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        const intro_s = document.getElementById("values") as HTMLElement;
        const h = intro_s?.offsetTop as number;

        document.addEventListener('scroll', () => {
            if (!(window.scrollY < (h))) {
                setVisible(true);
            }
        })
    })

    const [isDesktop] = useState<boolean>(!(window.innerWidth < 600))
    return (
        <div className={styles.values} id="values">
            <div className={isVisible ? styles.bottomtopup : styles.none}>
                <div className={styles.card}>

                    {
                        isDesktop ? <>

                            <Card title="VISÃO" text={visionText} side="left" />
                            <Card title="MISSÃO" text={missionText} secondary />
                            <Card title="VALORES" text={valuesText} side="right" />

                        </> :
                            <>
                                <Slider elementsPerSlide={1} elementsToMove={1}>
                                    <Card title="VISÃO" text={visionText} side="left" />
                                    <Card title="MISSÃO" text={missionText} secondary />
                                    <Card title="VALORES" text={valuesText} side="right" />
                                </Slider>
                            </>
                    }

                </div>
            </div>

        </div>
    )
}

export default Values;