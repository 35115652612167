import React, { ButtonHTMLAttributes } from "react";
import styles from "./RoundedButtonExpanded.module.css";

interface RoundedButtonExpandedProps extends ButtonHTMLAttributes<HTMLButtonElement>{
    children?: React.ReactNode;
    color?: 'primary' | 'secondary' | 'tertiary';

}

const RoundedButtonExpanded: React.FC<RoundedButtonExpandedProps> = ({ children, ...props }) => {
    return (
        <button className={styles.button} {...props}>
            <div>
                {children}
            </div>
        </button>
    )
}

export default RoundedButtonExpanded;